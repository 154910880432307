export default [
  {
    url: "/basic/notice-board",
    name: "Dashboard",
    icon: "MonitorIcon",
    slug: "dashboard",
    i18n: "Notice Board"
  },
  ...["NZ", 'AU'].includes(process.env.VUE_APP_REGION) ? [
    {
      url: "/basic/shop",
      name: "Shop",
      icon: "ShoppingBagIcon",
      slug: "shop",
      permission: 'Shop',
      i18n: "Shop"
    },
    {
      url: "/basic/orders",
      name: "Shop",
      slug: "orders",
      i18n: "My Orders",
      permission: 'Shop',
      icon: "TruckIcon"
    },
    {
      url: "/basic/invoices",
      name: "Invoice Management",
      slug: "invoice",
      i18n: "Invoices",
      permission: 'Invoice',
      icon: "DollarSignIcon"
    },
    {
      url: '/basic/pos',
      name: "POS",
      slug: "pos",
      i18n: "POS",
      permission: 'POS',
      icon: "DollarSignIcon",
    },
    {
      url: "/basic/insights",
      name: "Insights",
      icon: "BarChart2Icon",
      permission:'Insights',
      slug: "insights",
      i18n: "Insights",
    },
    {
      url: "/basic/referral",
      name: "Refer Someone to Fresh",
      slug: "referral",
      i18n: "Refer Someone to Fresh",
      icon: "UserPlusIcon"
    },
    {
      header: "Resource Hub",
      i18n: "Resource Hub",
      items: [
        {
          url: "/basic/resources",
          name: "Resources",
          icon: "FolderIcon",
          slug: "resources",
          i18n: "Resources"
        },
      ]
    },
    {
      header: "Training Hub",
      i18n: "Training Hub",
      items: [
        {
          url: "/basic/events",
          name: ['AU'].includes(process.env.VUE_APP_REGION) ? "Webinars & Training": "Events", // Applies to AU only for now
          icon: "CalendarIcon",
          slug: "events",
          i18n: ['AU'].includes(process.env.VUE_APP_REGION) ? "Webinars & Training": "Events" // Applies to AU only for now
        },
        // Applies to AU only
        ['AU'].includes(process.env.VUE_APP_REGION) && {
          url: "/basic/supplier-training",
          name: 'Supplier Training',
          icon: "CalendarIcon",
          slug: "supplier-training",
          i18n: 'Supplier Training'
        }
      ]
    },
    {
      header: "Compliance",
      permission: 'Compliance',
      i18n: "Compliance",
      items: [
        {
          url: "/basic/drugbook",
          name: "Drugbook",
          icon: "BookIcon",
          slug: "drugbook",
          permission: 'Drug',
          i18n: "Drugbook",
        },
        {
          url: "/basic/inventory",
          name: "Inventory",
          featherIcon: false,
          icon: "kitchen",
          slug: "inventory",
          i18n: "Inventory",
          permission: 'Drug',
        },
        {
          url: "/basic/compliance",
          name: "Compliance",
          icon: "GridIcon",
          slug: "compliance",
          permission: 'Invoice',
          i18n: "Compliance Checks",
        },
      ]
    }
  ] : [
    {
      url: "/basic/events",
      name: "Events",
      icon: "CalendarIcon",
      slug: "events",
      i18n: "Events"
    },
    {
      url: "/basic/referral",
      name: "Refer Someone to Fresh",
      slug: "referral",
      i18n: "Refer Someone to Fresh",
      icon: "UserPlusIcon"
    },
    {
      url: "/basic/resources",
      name: "Resources",
      icon: "FolderIcon",
      slug: "resources",
      i18n: "Resources"
    },
    {
      url: "/basic/invoices",
      name: "Invoice Management",
      slug: "invoice",
      i18n: "Invoices",
      permission: 'Invoice',
      icon: "DollarSignIcon"
    },
    {
      url: "/basic/drugbook",
      name: "Drugbook",
      icon: "BookIcon",
      slug: "drugbook",
      permission: 'Drug',
      i18n: "Drugbook",
    },
    {
      url: "/basic/compliance",
      name: "Compliance",
      icon: "GridIcon",
      slug: "compliance",
      permission: 'Invoice',
      i18n: "Compliance Checks",
    },
    {
      url: "/basic/inventory",
      name: "Inventory",
      icon: "CreditCardIcon",
      permission: 'Drug',
      slug: "inventory",
      i18n: "Inventory",
    },
    {
      url: "/basic/shop",
      name: "Shop",
      icon: "ShoppingBagIcon",
      slug: "shop",
      permission: 'Shop',
      i18n: "Shop"
    },
    {
      url: "/basic/orders",
      name: "Shop",
      slug: "orders",
      i18n: "My Orders",
      permission: 'Shop',
      icon: "TruckIcon"
    },
    {
      url: '/basic/pos',
      name: "POS",
      slug: "pos",
      i18n: "POS",
      permission: 'POS',
      icon: "DollarSignIcon",
    },
  ],
  {
    header: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings",
    permission: 'Settings',
    items: [
      {
        url: "/basic/clinics",
        name: "Clinics",
        slug: "basic-clinics-management",
        i18n: "Clinics",
        icon: "HomeIcon"
      },
      {
        url: "/basic/staff",
        name: "Staff",
        icon: "UsersIcon",
        slug: "staff",
        i18n: "Staff"
      },
      {
        url: "/basic/settings/products-list",
        name: "basic Products List",
        slug: "basic-products-list",
        icon: "CheckSquareIcon",
        i18n: "Products List"
      },
      {
        url: "/basic/settings/treatment-areas",
        name: "basic Treatment Areas",
        slug: "basic-treatment-areas",
        icon: "ShuffleIcon",
        i18n: "Treatment Areas"
      }
    ]
  }
];
